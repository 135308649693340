import { getEnv } from '$environments';
import { auth } from '$lib/auth';
import { browser, version } from '$app/environment';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

export let newrelicBrowserAgent = null;
let currentUser = null;

export const track = {
	exception(telemetry, customProperties) {
		customProperties = {
			...customProperties,
			user: currentUser,
		};
		if (newrelicBrowserAgent === null) {
			console.log('[NewRelic][track:exception] %o %o', telemetry, customProperties);
			return;
		}
		return newrelicBrowserAgent.noticeError(telemetry, customProperties);
	},
};

export function setupNewRelic() {
	if (!browser) {
		return;
	}

	const applicationID = getEnv().newRelicApplicationID;
	if (!applicationID) {
		console.warn('[NewRelic] no applicationID in environment settings');
		return;
	}

	const options = {
		init: {
			session_replay: {
				enabled: true,
				block_selector: '',
				mask_text_selector: '*',
				sampling_rate: 10.0,
				error_sampling_rate: 100.0,
				mask_all_inputs: true,
				collect_fonts: true,
				inline_images: false,
				inline_stylesheet: true,
				mask_input_options: {},
			},
			distributed_tracing: { enabled: true },
			privacy: { cookies_enabled: true },
			ajax: { deny_list: ['bam.nr-data.net'] },
		},
		info: { beacon: 'bam.nr-data.net', errorBeacon: 'bam.nr-data.net', licenseKey: 'NRJS-548daa526257d90f2c0', applicationID, sa: 1 },
		loader_config: { accountID: '4475278', trustKey: '4475278', agentID: applicationID, licenseKey: 'NRJS-548daa526257d90f2c0', applicationID },
	};

	newrelicBrowserAgent = new BrowserAgent(options);

	newrelicBrowserAgent.setApplicationVersion(version);

	auth.subscribe(({ user }) => {
		if (user) {
			newrelicBrowserAgent.setUserId(user.id);
			currentUser = {
				debtor: user.debtor,
				role: user.roleTypeName,
				roles: user.roles,
			};
		} else {
			newrelicBrowserAgent.setUserId(null);
			currentUser = null;
		}
	});
}
